
<template>
  <div v-if="navigation">
    <b-form @submit.prevent="onSubmit">
      <b-container class="full-w">
        <b-row>
          <b-col cols="12" lg="8">
            <b-card>
              <b-form-group
                :label="$t('navigation.name')"
                :class="{ error: v$.navigation.name.$errors.length }"
              >
                <b-form-input v-model="navigation.name"  />
                <div
                  class="input-errors"
                  v-for="error of v$.navigation.name.$errors"
                  :key="error.$uid"
                >
                  <small class="text-danger">{{ error.$message }}</small>
                </div>
              </b-form-group>

              <b-form-group
                :label="$t('navigation.placement')"
                label-for="name"
                :class="{ error: v$.navigation.placement.$errors.length }"
              >
                <v-select
                  id="placement"
                  v-model="navigation.placement"
                  :options="placements"
                />
                <div
                  class="input-errors"
                  v-for="error of v$.navigation.placement.$errors"
                  :key="error.$uid"
                >
                  <small class="text-danger">{{ error.$message }}</small>
                </div>
              </b-form-group>

              <b-form-group :label="$t('forms.publish')" label-for="published">
                <b-form-checkbox
                  v-model="navigation.onOff"
                  name="published"
                  switch
                />
              </b-form-group>
            </b-card>

            <b-card v-if="menu && menu.length">
              <nested-draggable @itemDeleted="removeFromMenu" :items="menu" />
            </b-card>
          </b-col>

          <b-col cols="12" lg="4">
            <b-card>
              <h4>{{ $t("navigation.customLink") }}</h4>
              <b-form-group :label="$t('navigation.customLinkLabel')">
                <b-form-input v-model="newItem.name" />
              </b-form-group>
              <b-form-group :label="$t('navigation.customLinkUrl')">
                <b-form-input v-model="newItem.url" />
              </b-form-group>
              <b-button variant="primary" class="mb-4" @click="addCustomLink">
                {{ $t("navigation.menuAdd") }}
              </b-button>

              <h4>{{ $t("navigations.pages") }}</h4>
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group
                  v-model="selectedPages"
                  :aria-describedby="ariaDescribedby"
                  :options="options"
                  value-field="item"
                  text-field="text"
                  class="d-flex flex-column"
                />
              </b-form-group>
              <b-button variant="primary" @click="addPages">
                {{ $t("navigation.menuAdd") }}
              </b-button>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
  </div>
</template>

<script>
import vSelect from "vue-select";
import useVuelidate from "@vuelidate/core";
import ListUtils from "@/mixins/ListUtils";
import ResourceUtils from "@/mixins/ResourceUtils";
import nestedDraggable from "./nested.vue";
import { v4 as uuidv4 } from "uuid";
import { preparePageItems, remove, iterate } from "../navigation";

export default {
  props: {
    navigationObj: { type: Object, default() {} },
    action: { type: String, default: null },
  },
  components: {
    vSelect,
    nestedDraggable,
  },
  data() {
    return {
      newItem: {
        id: null,
        items: [],
        url: "",
        name: "",
      },
      navigation: this.navigationObj,
      placements: [],
      pages: [],
      selectedPages: [],
      options: [],
      enableAdding: true,
      menu: [],
    };
  },
  mixins: [ListUtils, ResourceUtils],
  created() {
    this.loadPages();
    this.getEnumList("placement", this.placements);
    if(this.navigation.menu) {
      this.menu = this.navigation.menu
      iterate(this.menu, 0, false)
    }
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      navigation: {
        name: { required: this.$translateError('required') },
        placement: { required: this.$translateError('required') },
      },
    };
  },
  watch: {
    action() {
      if(this.action) {
        this.onSubmit()
      }
    },
  },
  methods: {
    addCustomLink() {
      const newItem = JSON.parse(JSON.stringify(this.newItem));
      newItem.id = uuidv4();
      newItem.items = [];
      this.menu.push(newItem);
      this.newItem.name = "";
      this.newItem.url = "";
    },
    addPages() {
      const selectedPagesCopy = JSON.parse(JSON.stringify(this.selectedPages))
      selectedPagesCopy.forEach(page => {
        page.id = uuidv4()
      });
      this.menu = this.menu.concat(selectedPagesCopy);
      this.selectedPages = [];
    },
    removeFromMenu(id) {
      this.menu = remove(this.menu, id);
    },
    loadPages() {
      this.$WebPages
        .getCollection({ params: { pagination: false }}, "id_name")
        .then((response) => {
          this.pages = response.data["hydra:member"];
          this.options = preparePageItems(this.pages);
        });
    },
    async onSubmit() {
      const isValid = await this.v$.$validate();
      if (isValid) {
        const placement = this.navigation.placement.id ? this.navigation.placement.id : this.navigation.placement;
        iterate(this.menu, 0, false)
        const body = {
          name: this.navigation.name,
          placement,
          menu: this.menu,
          onOff: this.navigation.onOff,
        }

        if (this.action) {
          this.action === "add" ?
            this.create(this.$Navigations, body, this.$t("navigation.created"), null, this.success, this.error) :
            this.update(this.$Navigations, this.navigation.id, body, this.$t("navigation.updated"), null, null, this.error);
            this.$emit("clearAction");
        }
      } else {
        this.$emit('clearAction')
      }
    },
    success() {
      this.$router.push(`/navigations`);
    },
    error() {
      this.$emit("clearAction");
    }
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
