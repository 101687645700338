import { v4 as uuidv4 } from 'uuid'

export const iterate = (obj, level, del) => {
  if (level === 0) {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of obj) {
      if (del) {
        delete item.id
      } else {
        item.id = uuidv4()
      }
    }
  }
  if (obj) {
    Object.keys(obj).forEach(key => {
      if (typeof obj[key] === 'object') {
        if (key === 'items' && obj[key] && obj[key].length) {
          // eslint-disable-next-line no-restricted-syntax
          for (const item of obj[key]) {
            if (del) {
              delete item.id
            } else {
              item.id = uuidv4()
            }
          }
        }
        iterate(obj[key], level + 1, del)
      }
    })
  }
}

export const preparePageItems = pages => {
  const result = []
  // eslint-disable-next-line no-restricted-syntax
  for (const page of pages) {
    result.push({
      text: page.name,
      item: {
        name: page.name, url: page.url, id: uuidv4(), items: [],
      },
    })
  }
  return result
}

export const remove = (items, id) => items.map(item => ({ ...item })).filter(item => {
  if ('items' in item) {
    // eslint-disable-next-line no-param-reassign
    item.items = remove(item.items, id)
  }
  return item.id !== id
})

export const getPositionOptions = context => {
  const options = []
  context.$Enums.getResource({ id: 'position' }).then(response => {
    if (response.status === 200) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key of response.data.keys) {
        options.push({ text: context.$helper.getEnumTranslation('position', key, context.$i18n.locale), value: key })
      }
    }
  })
  return options
}
